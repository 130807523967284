import { Pipe, PipeTransform } from "@angular/core";
import { ReviewStatusTypesEnum } from "../common/constants/workflow.statustypes";

@Pipe({
    name: "reviewStatusColor"
})
export class ReviewStatusColorPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        switch (value) {
            case ReviewStatusTypesEnum.APPROVED:
                return 'text-green';
            case ReviewStatusTypesEnum.ADD_COMMENT:
                return 'text-bold';
            case ReviewStatusTypesEnum.REVIEW_FAILED:
                return 'text-red text-bold';
            default:
                return '';
                break;
        }
    }
}
