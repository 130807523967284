import { Injectable } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
// import * as pdfjsLib from 'pdfjs-dist';

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public isShowLoader = true;
  public isLoggedIn = false;
  pageSize = 10;
  pageNo = 0;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  lstItemsToHide: HideItem[];
  // constructor(
  //   private _commonDataService: CommonDataService
  // ) { }

  setTokenGuid(token: string) {
    localStorage.setItem("InfoToken", token);
  }

  getProfilePageInputSettings() {
    this.lstItemsToHide = [
      {
        name: "profile",
        items: [],
        section: [],
      },
      {
        name: "education",
        items: [
          "address",
          "telephoneNo",
          "city",
          "empCountry",
          "empState",
          "zipcode",
          "uadress",
          "utelephone",
          "ucity",
          "uniEmpCountry",
          "uniEmpState",
          "uzcode",
          "ssn",
        ],
        section: [],
      },
      {
        name: "employment",
        section: ["employerAddressSection"],
        items: [
          "website",
          "offAddress",
          "email",
          "employeeCountry",
          "employeeState",
          "department",
          "AName",
          "ADetails",
          "startBaseSalary",
          "otherCompension",
          "total",
          "finalBs",
          "otherCompension2",
          "total2",
        ],
      },
      {
        name: "identity",
        items: [],
        section: [],
        // section: ["passportOptional", "panOptional"],
      },
      {
        name: "ref",
        items: ["mentionDateRef"],
        section: ["canRefBee"],
      },
      {
        name: "address",
        items: [
          "landLineTelephone",
          "addressProofType",
          "addressState",
          "addressCountry",
        ],
        section: [],
      },
    ];
  }

  getItemsBasedOnType(type: string) {
    this.getProfilePageInputSettings();
    var item = this.lstItemsToHide.filter((x) => x.name == type);
    if (item != null && item.length > 0) return item[0].items;
    return [];
  }

  getSectionBasedOnType(type: string) {
    this.getProfilePageInputSettings();
    var item = this.lstItemsToHide.filter((x) => x.name == type);
    if (item != null && item.length > 0) return item[0].section;
    return [];
  }

  isItemExists(type: string, inputName: string) {
    this.getProfilePageInputSettings();
    var item = this.lstItemsToHide.filter((x) => x.name == type);
    if (item != null && item.length > 0) {
      var items = item[0].items;
      var singleItem = items.filter((x) => x == inputName);
      if (singleItem != null && singleItem.length > 0) {
        return true;
      }
    }
    return false;
  }

  getTokenGuid() {
    return localStorage.getItem("InfoToken");
  }
  clearToken() {
    return localStorage.removeItem("InfoToken");
  }

  setLogoUrl(logoUrl: string) {
    localStorage.setItem("logoUrl", logoUrl);
  }
  getLogoUrl() {
    return localStorage.getItem("logoUrl");
  }

  setUserTypeId(userTypeId: string) {
    localStorage.setItem("userTypeId", userTypeId);
  }
  setEnterpriseId(enterpriseId: string) {
    localStorage.setItem("enterpriseId", enterpriseId);
  }
  getUserTypeId() {
    return localStorage.getItem("userTypeId");
  }
  setLoginName(name: string) {
    localStorage.setItem("loginName", name);
  }
  getLoginName() {
    return localStorage.getItem("loginName");
  }
  getEnterpriseId() {
    return localStorage.getItem("enterpriseId");
  }
  setGroupEnterprises(lst: any) {
    localStorage.setItem("groupEnterprises", JSON.stringify(lst));
  }

  getGroupEnterprises() {
    return JSON.parse(localStorage.getItem("groupEnterprises"));
  }

  setGroupEnterpriseId(groupEnterpriseId) {
    localStorage.setItem("selectedGroupEnterpriseId", groupEnterpriseId);
  }

  getGroupEnterpriseId() {
    return localStorage.getItem("selectedGroupEnterpriseId");
  }

  downLoadExcel(res: any, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  }

  downLoadPdf(res: any, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  }

  clearStatusDropdown(event: any, multiSelectRef: MatSelect) {
    if (event.source._keyManager._activeItemIndex === 0)
      multiSelectRef.options.forEach((data: MatOption) => data.deselect());
    else if (event.source._keyManager._activeItemIndex === 1)
      multiSelectRef.options.forEach((data: MatOption) => {
        if (data.id !== "mat-option-0")
          data.select()
      });
  }


  // extractImagesFromPdf(event: any) {
  //   var file = event.target.files[0];
  //   // Load the PDF document
  //   let fileReader = new FileReader();
  //   fileReader.onload = function (eve) {
  //     // @ts-ignore
  //     var typedarray = new Uint8Array(this.result);
  //     console.log(typedarray);

  //     const loadingTask = pdfjsLib.getDocument(typedarray);
  //     loadingTask.promise.then(pdf => {
  //       for (var i = 1; i <= pdf.numPages; i++) {
  //         // Get the page object
  //         pdf.getPage(i).then(function (page) {
  //           // Get the page's viewport
  //           var scale = 1.5;
  //           var viewport = page.getViewport({
  //             scale: scale
  //           });
    

  //           // Loop through each page item
  //           // @ts-ignore
  //           var task = page.getOperatorList();
  //           task.then(function (data) {
  //             var imageData = [];

  //             for (var j = 0; j < data.argsArray.length; j++) {
  //               var item = data.argsArray[j];

  //               // If the item is an image, extract it
  //               if (item[0] === 'paintImageXObject') {
  //                 // @ts-ignore
  //                 var image = page.objs.get(item[1]);

  //                 var canvas = document.createElement('canvas');
  //                 canvas.width = image.width;
  //                 canvas.height = image.height;

  //                 var context = canvas.getContext('2d');
  //                 context.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);

  //                 var dataURL = canvas.toDataURL();

  //                 imageData.push(dataURL);
  //               }
  //             }

  //             // Do something with the extracted images
  //             console.log(imageData);
  //           });
  //         });
  //       }
  //     })

  //   }
  //   fileReader.readAsArrayBuffer(file);


  // }

}

class HideItem {
  name: string;
  items: string[];
  section: string[];
}
