<div>
  <div class="row">
    <div class="col-sm-3">
      <div class="pull-left email-field">
        <mat-form-field appearance="outline">
          <mat-label>To</mat-label><input appCustomValidator matInput type="email" custommax="50" [(ngModel)]="emailTo"
            id="emailTo" name="emailTo" placeholder="To" />
        </mat-form-field>
      </div>
      <div class="pull-right">
        <a>
          <i class="fa fa-plus" matTooltip="Add Email" (click)="addMailItem('to')"></i>
        </a>&nbsp;
      </div>
    </div>
    <div class="col-sm-9">
      <div class="mail-item" *ngFor="let item of emailToList">
        <strong>
          {{ item }} &nbsp;<a>
            <i class="fa fa-trash" matTooltip="Remove" (click)="removeMailItem('to',item)"></i></a>&nbsp;
        </strong>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3">
      <div class="pull-left email-field">
        <mat-form-field appearance="outline">
          <mat-label>CC</mat-label><input appCustomValidator matInput type="email" custommax="50" [(ngModel)]="emailCc"
            id="emailCc" name="emailCc" placeholder="CC" />
        </mat-form-field>
      </div>
      <div class="pull-right">
        <a>
          <i class="fa fa-plus" matTooltip="Add Email" (click)="addMailItem('cc')"></i>
        </a>&nbsp;
      </div>
    </div>
    <div class="col-sm-9">
      <div class="mail-item" *ngFor="let item of emailCcList">
        <strong>
          {{ item }} &nbsp;<a>
            <i class="fa fa-trash" matTooltip="Remove" (click)="removeMailItem('cc',item)"></i></a>&nbsp;
        </strong>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3">
      <div class="pull-left email-field">
        <mat-form-field appearance="outline">
          <mat-label>BCC</mat-label><input appCustomValidator matInput type="email" custommax="50"
            [(ngModel)]="emailBcc" id="emailBcc" name="emailBcc" placeholder="BCC" />
        </mat-form-field>
      </div>
      <div class="pull-right">
        <a>
          <i class="fa fa-plus" matTooltip="Add Email" (click)="addMailItem('bcc')"></i>
        </a>&nbsp;
      </div>
    </div>
    <div class="col-sm-9">
      <div class="mail-item" *ngFor="let item of emailBccList">
        <strong>
          {{ item }} &nbsp;<a>
            <i class="fa fa-trash" matTooltip="Remove" (click)="removeMailItem('bcc',item)"></i></a>&nbsp;
        </strong>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-9 email-subject">
    <strong class="">
      {{reportTypeId > 0 ?(reportTypeId===1?'Final':'Interim'):''}} {{mailQueue.emailSubject}}
    </strong>
    <!-- <mat-form-field appearance="outline">
      <mat-label>BCC</mat-label><input appCustomValidator matInput type="text" custommax="300" [disabled]="true"
        [(ngModel)]="mailQueue.emailSubject" id="emailBcc" name="emailBcc" placeholder="BCC" />
    </mat-form-field> -->
  </div>
</div>

<div>
  <!-- <ck-editor #myckeditor_1="ngModel" name="myckeditor_1" required [config]="ckeConfig" debounce="500"
    [(ngModel)]="mailQueue.emailBody" language="en" [fullPage]="true"></ck-editor> -->
    <app-iq-editor *ngIf="mailQueue&&mailQueue.emailBody" [content]="mailQueue.emailBody"></app-iq-editor>
    <!-- <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
      <ngx-editor [editor]="editor" [ngModel]="mailQueue.emailBody" 
      [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div> -->
</div>