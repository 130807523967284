import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { IFinalreportTypeCount } from 'src/app/modules/checks/clarification/hr-confirm.models';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  // imports:[BaseChartDirective]
})
export class PieChartComponent implements OnInit {
  @Input()
  data: IFinalreportTypeCount[] = [];

  oldData: IFinalreportTypeCount[] = [];
  @Input()
  title = '';
  @Input()
  chartType = 'doughnut';
  @Input()
  height: string = null;
  @Input()
  width: string = null;
  public pieChartLabels = [];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public pieChartData: ChartData<'doughnut'> = {
    labels: this.pieChartLabels,
    datasets: [
      {
        data: [],
        backgroundColor: ["#409f40", "orange", "red", "#dc3545", "brown", "gray", "#2f8e68", "#007bff", "#dc3545", "green", "skyblue"]
      }
    ],
  };
  public pieChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false
  };

  public chartColors: any[] = [
    {
      backgroundColor: ["#409f40", "orange", "red", "#dc3545", "brown", "gray", "#2f8e68", "#007bff", "#dc3545", "green", "skyblue"]
    }];
  constructor() {
  }

  ngOnInit() {

  }


  ngAfterViewChecked() {
    if (this.data && this.data.length && this.data.length > 0) {
      this.oldData = this.data;
      this.pieChartData.labels = []
      this.pieChartData.datasets.at(0).data = [];
      for (let row of this.data) {
        this.pieChartData.labels.push(row.finalRptColorId == 1 ? 'Green' : row.finalRptColorId == 2 ? 'Orange' : 'Red');
        this.pieChartData.datasets.at(0).data.push(row.count);
      }
      if (this.chart && this.chart.chart) {
        this.chart.chart.update();
      }
    }
  }
}

