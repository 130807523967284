import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";

@Component({
  selector: "app-year",
  templateUrl: "./year.component.html",
  styleUrls: ["./year.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class YearComponent implements OnInit {
  private _year: any;
  @Input() index: number;
  @Input() name: string;
  @Input() placeHolder: string;
  @Input() isRequired: boolean;
  @Input()
  get year(): any {
    return this._year;
  }
  set year(year: any) {
    this._year = year;
    this.yearChange.emit(this._year);
  }

  @Output()
  yearChange: EventEmitter<any> = new EventEmitter<any>(true);
  lstYear: [];

  constructor() {}

  ngOnInit() {
    const currentDate = new Date();
    const years = [];
    for (let i = currentDate.getFullYear(); i >= 1950; i--) {
      years.push(i);
    }
    this.lstYear = <any>years;
  }
  onChange(e) {
    this.year = e.value;
  }
}
