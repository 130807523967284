import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userNodes'
})
export class UserNodesPipe implements PipeTransform {
  transform(array: any[], id: string = "userID", parentId: string = "parentId", rootValue: any = "0"): any[] {
    return this.filterNodes(array, id, parentId, rootValue);
  }
  filterNodes(array: any[], id: string, parentId: string, parentValue: any): any[] {
    return array && array.filter((node) => {
      return node[parentId] === +parentValue;
    }).map((node) => {
      node["items"] = this.filterNodes(array, id, parentId, node[id]);
      return node;
    });
  }
}
