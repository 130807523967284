import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { map, catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { CommonService } from "./common.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonDataService {
  api_url = environment.apiUrl;
  token = "";
  headers;
  options;
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    public _commonService: CommonService,
    public _router: Router
  ) {
  }
  constructHeader() {
    this.token = this._commonService.getTokenGuid();
    const groupEnterpriseId = this._commonService.getGroupEnterpriseId();
    this.headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    if (groupEnterpriseId != null) {
      this.headers = this.headers.append("GroupEnterpriseId", groupEnterpriseId)
    }
    this.options = { headers: this.headers };
  }

  public loginDataAsync<T>(requestUrl: string, data: any): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const options = { headers: this.headers };
    this._commonService.isShowLoader = true;
    return this._http
      .post<T>(this.api_url + requestUrl, data, options)
      .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        }), catchError(error => {
          this._commonService.isShowLoader = false;
          if (error.status === 401) {
            this._toastrService.error("Invalid Username or Password");
          } else if (error.status === 422) {
            this._toastrService.error(error._body);
          }
          else {
            return this.handleError(error._body);
          }
        }));
  }

  public forgotPassword<T>(requestUrl: string, data: any): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const options = { headers: this.headers }
    this._commonService.isShowLoader = true;
    return this._http
      .post<T>(this.api_url + requestUrl, data, options)
     .pipe(
        map(res => {
        this._commonService.isShowLoader = false;
        return <T>res;
      })
      , catchError(error => {
        this._commonService.isShowLoader = false;
        if (error.status === 401) {
          this._toastrService.error(JSON.stringify(error));
        } else {
          return this.handleError(error._body);
        }
      }));
  }

  public resetpassword<T>(requestUrl: string, data: any): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const options = { headers: this.headers }
    this._commonService.isShowLoader = true;
    return this._http
      .post<T>(this.api_url + requestUrl, data, options)
     .pipe(
        map(res => {
        this._commonService.isShowLoader = false;
        return <T>res;
      })
      , catchError(error => {
        this._commonService.isShowLoader = false;
        if (error.status === 401) {
          this._toastrService.error(JSON.stringify(error));
        } else {
          return this.handleError(error._body);
        }
      }));
  }

  public postNoToken<T>(requestUrl: string, data: any): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const options = { headers: this.headers }
    this._commonService.isShowLoader = true;
    return this._http
      .post<T>(this.api_url + requestUrl, data, options)
     .pipe(
        map(res => {
        this._commonService.isShowLoader = false;
        return res;
      })
      , catchError(error => {
        this._commonService.isShowLoader = false;
        if (error.status === 401) {
          this._toastrService.error(JSON.stringify(error));
        } else {
          return this.handleError(error._body);
        }
      }));
  }

  public getNoToken<T>(requestUrl: string): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const options = { headers: this.headers }
    this._commonService.isShowLoader = true;
    return this._http
      .get<T>(this.api_url + requestUrl, options)
     .pipe(
        map(res => {
        this._commonService.isShowLoader = false;
        return <T>res;
      })
      , catchError(error => {
        this._commonService.isShowLoader = false;
        if (error.status === 401) {
          this._toastrService.error(JSON.stringify(error));
        } else {
          return this.handleError(error._body);
        }
      }));
  }

  public getDataAsync<T>(requestUrl: string): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      const options = { headers: this.headers };
      return this._http
        .get<T>(this.api_url + requestUrl, options)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }

  public postDataAsync<T>(requestUrl: string, data: any): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      const options = { headers: this.headers };
      return this._http
        .post<T>(this.api_url + requestUrl, data, options)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }
  public patchDataAsync<T>(requestUrl: string, data: any): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      const options = { headers: this.headers };
      return this._http
        .patch<T>(this.api_url + requestUrl, data, options)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }

  public deleteDataAsync<T>(requestUrl: string): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      const options = { headers: this.headers };
      return this._http
        .delete<T>(this.api_url + requestUrl, options)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }

  public putDataAsync<T>(requestUrl: string, data: any): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      const options = { headers: this.headers };
      return this._http
        .put<T>(this.api_url + requestUrl, data, options)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }
  public postUploadDataAsync<T>(
    requestUrl: string,
    data: any,
    parameters: any
  ): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      const options = { headers: this.headers, params: parameters };
      this._commonService.isShowLoader = true;
      return this._http
        .post<T>(this.api_url + requestUrl, data, options)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>res;
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }

  public handleError(error: Response | any) {
    if (error.status === 401) {
      this._router.navigate(["/login"]);
    } else {
      let errorMessage: string;
      errorMessage = error.message ? error.message : error._body;
      if (error.status === 500) {
        this._toastrService.error("Error Occured.");
      } else {
        this._toastrService.error(errorMessage);
      }
      return throwError(errorMessage);
    }
  }
  public checkToken() {
    if (this._commonService.getTokenGuid()) {
      return true;
    } else {
      this._router.navigate(["/login"]);
    }
  }

  public getIPAddress<T>(): Observable<T> {
    return this._http.get<T>('https://api.ipify.org?format=json')
     .pipe(
        map(res => {
        return <T>res;
      })
      , catchError(error => {
        return this.handleError(error);
      }));
  }

  public postDataGetBlobAsync<T>(requestUrl: string, data: any): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      let overideOptions = this.options;
      overideOptions.responseType = 'blob' as 'blob';
      return this._http
        .post(this.api_url + requestUrl, data, overideOptions)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>(res as unknown);
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }

  public PostDataNoTokenBlobAsync<T>(requestUrl: string, data: any): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let overideOptions = this.options;
    overideOptions.responseType = 'blob' as 'blob';
    this._commonService.isShowLoader = true;
    return this._http
      .post<T>(this.api_url + requestUrl, data, overideOptions)
     .pipe(
        map(res => {
        this._commonService.isShowLoader = false;
        return <T>(res as unknown);
      })
      , catchError(error => {
        this._commonService.isShowLoader = false;
        return this.handleError(error);
      }));
  }


  public ExternalPostDataNoTokenBlobAsync<T>(requestUrl: string, data: any): Observable<T> {
    this.constructHeader();
    const headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let overideOptions = this.options;
    overideOptions.responseType = 'blob' as 'blob';
    this._commonService.isShowLoader = true;
    return this._http
      .post<T>(requestUrl, data, overideOptions)
     .pipe(
        map(res => {
        this._commonService.isShowLoader = false;
        return <T>(res as unknown);
      })
      , catchError(error => {
        this._commonService.isShowLoader = false;
        return this.handleError(error);
      }));
  }

  public getDataGetBlobAsync<T>(requestUrl: string): Observable<T> {
    if (this.checkToken()) {
      this.constructHeader();
      this._commonService.isShowLoader = true;
      let overideOptions = this.options;
      overideOptions.responseType = 'blob' as 'blob';
      return this._http
        .get<T>(this.api_url + requestUrl, overideOptions)
       .pipe(
        map(res => {
          this._commonService.isShowLoader = false;
          return <T>(res as unknown);
        })
        , catchError(error => {
          this._commonService.isShowLoader = false;
          return this.handleError(error);
        }));
    }
  }

}
