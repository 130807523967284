<div style="display: block;">
  <h6 class="text-center">{{title}}</h6>
  <div class="bg-white"  [style.height]="height">
    <!-- <app-chart-host chartType="doughnut"> -->
      <canvas baseChart [data]="pieChartData" [style.height]="height" [legend]="true" [options]="pieChartOptions" [labels]="pieChartLabels"
        [type]="chartType"></canvas>

    <!-- </app-chart-host> -->

  </div>
</div>