<h5 class="page-header-text text-center">OTP</h5>
<div>
  OTP has been sent to your registered email account.
</div>
<hr />
<form #form="ngForm">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>OTP</mat-label><input appCustomValidator required custommin="6" custommax="6" matInput
        [(ngModel)]="model.otp" placeholder="OTP*" name="otp" />
    </mat-form-field>
  </div>
  <div class="text-center">
    <button type="button" data-dismiss="modal" title="Cancel" (click)="onNoClick()" mat-raised-button color="default">
      Cancel</button>&nbsp;&nbsp;
    <button type="submit" data-dismiss="modal" (click)="onConfirmClick()" title="Confirm" mat-raised-button
      color="primary" [disabled]="!form.valid">
      Confirm
    </button>
  </div>
</form>