import { Component, Input, OnInit, AfterViewChecked, ViewChild, input } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData } from 'chart.js';
import { IProfileCountResponse } from 'src/app/modules/checks/clarification/hr-confirm.models';
import { format } from "date-fns";
import * as _ from "lodash";

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, AfterViewChecked {
  @Input()
  profileCountResponse: IProfileCountResponse[] = [];
  profileCountResponseOld: IProfileCountResponse[] = [];
  @Input()
  title = '';
  @Input()
  lineChartType = 'bar';
  @Input()
  height: string = null;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  public lineChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#07cdae']
      },
    ]
  };
  public lineChartLegend = false;
  public lineChartPlugins = [];
  public lineChartLabels: any[] = [];
  public lineChartColors: any[] = [
    {
      borderColor: '#07cdae',
      backgroundColor: '#07cdae',
    }
  ];

  constructor() { }

  ngOnInit() { }

  ngAfterViewChecked() {
    if (this.profileCountResponse.length !== this.profileCountResponseOld.length) {
      this.profileCountResponseOld = JSON.parse(JSON.stringify(this.profileCountResponse));
      this.lineChartData.datasets[0].data = [];
      this.lineChartData.labels = [];
      this.profileCountResponse = _.orderBy(this.profileCountResponse, ['createdDate'], ['asc']);
      for (let o of this.profileCountResponse) {
        this.lineChartData.datasets[0].data.push(o.count);
        const formattedDate = format(new Date(o.createdDate), 'yyyy-MM-dd');
        this.lineChartData.labels.push(formattedDate);
        this.lineChartLabels.push(formattedDate);
      }
      console.log(this.lineChartData);
      // Ensure the chart is updated
      if (this.chart && this.chart.chart) {
        this.chart.chart.update();
      }
    }
  }
}
