import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Editor } from 'ngx-editor';
import { commonConstants } from 'src/app/common/constants/common.constants';
import { MailQueueModel } from 'src/app/common/models/report.calss';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MailComponent implements OnInit {
  // @ViewChild("myckeditor_1", { static: false }) ckeditor: any;
  
  @Input() mailQueue: MailQueueModel;
  @Input() reportTypeId: number;
  // ckeConfig: any;
  emailToList: string[];
  emailCcList: string[];
  emailBccList: string[];
  emailTo: string;
  emailCc: string;
  emailBcc: string;
  editor: Editor;
  constructor() { }

  ngOnInit() {
    this.editor = new Editor();
    // this.ckEditorConfig();
    setTimeout(() => {
      if (this.mailQueue) {
        this.emailToList = this.mailQueue.emailTo ? this.mailQueue.emailTo.split(';') : [];
        this.emailCcList = this.mailQueue.emailCc ? this.mailQueue.emailCc.split(';') : [];
        this.emailBccList = this.mailQueue.emailBcc ? this.mailQueue.emailBcc.split(';') : [];
      }
    }, 2000);
  }

  removeMailItem(type: string, item: string) {
    switch (type) {
      case 'to':
        if (this.emailToList.length > 0) {
          this.emailToList.splice(this.emailToList.indexOf(item), 1);
        }
        break;
      case 'cc':
        if (this.emailCcList.length > 0) {
          this.emailCcList.splice(this.emailCcList.indexOf(item), 1);
        }
        break;
      case 'bcc':
        if (this.emailBccList.length > 0) {
          this.emailBccList.splice(this.emailBccList.indexOf(item), 1);
        }
        break;
    }
    this.mapEmails();
  }

  addMailItem(type: string) {
    switch (type) {
      case 'to':
        if (this.emailTo.match(commonConstants.EMAIL_PATTERN))
          this.emailToList.push(this.emailTo);
        this.emailTo = '';
        break;
      case 'cc':
        if (this.emailCc.match(commonConstants.EMAIL_PATTERN))
          this.emailCcList.push(this.emailCc);
        this.emailCc = '';
        break;
      case 'bcc':
        if (this.emailBcc.match(commonConstants.EMAIL_PATTERN))
          this.emailBccList.push(this.emailBcc);
        this.emailBcc = '';
        break;
    }
    this.mapEmails();
  }

  mapEmails() {
    this.mailQueue.emailTo = this.emailToList.length > 0 ? this.emailToList.join(';') : '';
    this.mailQueue.emailCc = this.emailCcList.length > 0 ? this.emailCcList.join(';') : '';
    this.mailQueue.emailBcc = this.emailBccList.length > 0 ? this.emailBccList.join(';') : '';
  }

  // ckEditorConfig() {

  //   this.ckeConfig = {
  //     allowedContent: true,
  //     forcePasteAsPlainText: true,
  //     font_names: "Arial;Times New Roman;Verdana",
  //     toolbarGroups: [
  //       { name: "document", groups: ["mode", "document", "doctools"] },
  //       { name: "clipboard", groups: ["clipboard", "undo"] },
  //       {
  //         name: "editing",
  //         groups: ["find", "selection", "spellchecker", "editing"]
  //       },
  //       { name: "forms", groups: ["forms"] },
  //       "/",
  //       { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
  //       {
  //         name: "paragraph",
  //         groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
  //       },
  //       { name: "links", groups: ["links"] },
  //       { name: "insert", groups: ["insert"] },
  //       "/",
  //       { name: "styles", groups: ["styles"] },
  //       { name: "colors", groups: ["colors"] },
  //       { name: "tools", groups: ["tools"] },
  //       { name: "others", groups: ["others"] },
  //       { name: "about", groups: ["about"] }
  //     ],
  //     height: 500,
  //     skin: "kama"
  //     //   removeButtons:
  //     //     "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,Outdent,Indent,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Image,Flash,HorizontalRule,Smiley,SpecialChar,Iframe,Maximize,ShowBlocks"
  //   };
  // }
}
