import { AfterContentInit, Directive, ElementRef, Input } from "@angular/core";
import { AuthGuard } from "src/app/common/services/auth.service";
import { userTypesEnum } from "../common/constants/common.constants";
import { CommonService } from "../common/services/common.service";
declare var $: any;

@Directive({
  selector: "[hideCheck]",
})
export class HideControlDirective implements AfterContentInit {
  public _CommonService: CommonService;
  public _AuthGuard: AuthGuard;
  public type: string;
  @Input("indexnumber")
  public index: string;

  public constructor(
    private el: ElementRef,
    com: CommonService,
    AuthGuard: AuthGuard
  ) {
    this._CommonService = com;
    this._AuthGuard = AuthGuard;
    this.type = this.el.nativeElement.getAttribute("hidecheck");
    this.index = this.el.nativeElement.getAttribute("indexnumber");
    if (this.index == null) {
      this.index = "";
    }
  }

  public ngAfterContentInit() {
    if (
      this._AuthGuard.userTypeId == userTypesEnum.ENTERPRISE_USER ||
      this._AuthGuard.userTypeId == userTypesEnum.PROFILE_USER ||
      this._AuthGuard.userTypeId == userTypesEnum.DataEntry
      //|| this._AuthGuard.userTypeId == userTypesEnum.SUPER_ADMIN //ForTesting
    ) {
      setTimeout(() => {
        var elements = $(this.el.nativeElement).find("mat-form-field");
        var items = this._CommonService.getItemsBasedOnType(this.type);
        var sections = this._CommonService.getSectionBasedOnType(this.type);
        if (items != null && items.length > 0) {
          if (elements != null && elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
              if (elements[i].id != "") {
                this.hideControl(items, elements[i].id);
              }
            }
          }
        }
        if (sections != null && sections.length > 0) {
          for (var i = 0; i < sections.length; i++) {
            var item = $("#" + sections[i] + this.index);
            if (item != null) {
              item.hide();
            }
          }
        }
      }, 500);
    }
  }

  hideControl(items: any, id: string) {
    var exists = items.filter((x) => x == id.replace(this.index, ""));

    if (exists != null && exists.length > 0) {
      $("#" + id).remove();
    }
  }
}
