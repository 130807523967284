<div>
  <strong class="page-header-text">SubOrdinates</strong>
  <div class="pull-right">
    <a class="text-right" (click)="onCloseClick()" matTooltip="Close"><i class="fa fa-close"></i></a>
  </div>
  <hr />
</div>
<div class="tree">
  <ng-template #List let-items>
    <ul *ngIf="items&&items.length>0">
      <li *ngFor="let item of items">
        <span>
          <a href="#">
            <div>
              <div class="pull-left img-div">
                <img class="circle login-icon" src="../assets/images/user.png">
              </div>
              <div class="pull-right dv-user-details text-right">
                <div>{{item.firstName}} {{item.lastName}}</div>
                <div>{{item.email}}</div>
                <div>{{item.role}}</div>
                <div>
                  {{item.items.length}} Direct Reports
                </div>
              </div>
            </div>
          </a>
        </span>
        <ng-container *ngTemplateOutlet="List; context:{ $implicit: item.items }"></ng-container>
      </li>
    </ul>
  </ng-template>
  <ng-container *ngTemplateOutlet="List; context:{ $implicit: userListResponse | userNodes }"></ng-container>
</div>