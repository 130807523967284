import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from "./common.service";
import { App_Urls } from "../constants/appurls";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class AuthGuard  {
  canAdd = false;
  canView = false;
  canEdit = false;
  canDelete = false;
  isActive = false;
  userTypeId = 0;
  constructor(
    private router: Router,
    private _sharedService: SharedService,
    private _commonService: CommonService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (!this._commonService.getTokenGuid()) {
      this.router.navigate(["/login"]);
      return false;
    } else {
      let navPath = "";
      if (_route.data["module"]) {
        navPath = _route.data["module"] + "/";
      }
      navPath = navPath + _route.data["pagePriv"];
      return this._sharedService
        .getPrivilegeBasedOnNavUrl(navPath)
        .pipe(map(auth => {
          if (auth) {
            const result = auth;
            this.canAdd = result["canAdd"];
            this.canView = result["canView"];
            this.canEdit = result["canEdit"];
            this.canDelete = result["canDelete"];
            this.isActive = result["isActive"];
            this.userTypeId = result["userTypeId"];
            if (!this.isActive || !this.canView) {
              this.router.navigate(["/unauthorized"]);
            }
            console.log("authenticated");
            return true;
          }
          console.log("not authenticated");
          this.router.navigateByUrl("/login");
          return false;
        }), first());
    }
  }
}
