<h6 class="page-header-text">Checks</h6>
<div class="row col-sm-12">
  <div class="col-sm-2" *ngFor="let item of lstItems; let i = index" tabindex="-1">
    <div  class=" check-item check{{ i }}" [ngClass]="!isDashboard ? 'check' : ''">
      <div style="margin: auto;" class="text-center">
        <div>
          <mat-checkbox tabindex="0" *ngIf="!isDashboard && isEnterprise" class="example-margin" style="margin-right: 10px"
            [(ngModel)]="item.isChecked" [ngModelOptions]="{ standalone: true }"></mat-checkbox><span>{{
            item.profileCheckType }}</span><br />
         
        </div>
        
        <!-- <div *ngIf="item.isChecked" class="mt-1">
          <mat-form-field appearance="outline" class="price-field-width">
            <mat-label>Check Price</mat-label>
            <input type="number" appCustomValidator [(ngModel)]="item.checkPrise" name="checkPrise" matInput custommin="1"
              custommax="5" placeholder="Check Prise" required />
          </mat-form-field>
        </div> -->
        <div class="text-center" *ngIf="!isDashboard && !isEnterprise">
          <button type="button" mat-icon-button (click)="removeItemClick(item)" [disabled]="item.isDisableRemoveItem">
            <mat-icon>remove_circle</mat-icon>
          </button>
          <span *ngIf="!isDashboard && !isEnterprise">{{ item.items }}</span>
          <button type="button" mat-icon-button color="primary" (click)="addItemClick(item)" tabindex="0"
            [disabled]="item.isDisableAddItem">
            <mat-icon class="check-add">add_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>