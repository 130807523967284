export enum profileStatusTypesEnum {
    // PROFILE_ADD_INPROGRESS = 1,
    // SUBMITTED_BY_EMPLOYEE = 2,
    // MODIFIED_BY_EMPLOYEE = 3,
    // VERIFICATIONIN_PROGRESS = 4,
    // VERIFICATION_FAILED = 5,
    // VERIFICATION_COMPLETED = 6,
    // SUBMITTEDBY_HR = 7,
    // MODIFIEDBY_HR = 8,
    // READY_FOR_VERIFICATION = 9

    PROFILE_IN_PROGRESS_BY_EMPLOYEE = 1,
    PROFILE_SUBMITTED_BY_EMPLOYEE = 2,
    PROFILE_IN_PROGRESS = 3,
    PROFILE_REFER_BACK_TO_EMPLOYEE = 4,
    PROFILE_REFER_BACK_TO_HR = 5,
    PROFILE_PROCESS_INITIATED = 6,
    PROFILE_PROCESS_APPROVED = 7,
    PROFILE_PROCESS_IN_PROGRESS = 8,
    PROFILE_PROCESS_CANCELLED = 9,
    PROFILE_PROCESS_COMPLETED = 10,
    REVERT = 11
}

export enum ReviewStatusTypesEnum {
    REPORT_DRAFTED = 1,
    UNDER_REVIEW = 2,
    REVIEW_COMPLETED = 3,
    APPROVED = 4,
    BILLING_COMPLETED = 5,
    ADD_COMMENT = 6,
    DELETE = 7,
    REVIEW_FAILED = 8
}

export enum ReviewActionsTypesEnum {
    DRAFT = 1,
    MARK_UNDER_REVIEW = 2,
    REVIEW_COMPLETE = 3,
    APPROVED = 4,
    BILLING_COMPLETED = 5,
    ADD_COMMENT = 6,
    DELETE = 7,
    REVIEW_FAILED = 8

}
export enum UploadTypes {
    UPLOADREPORT = 1,
    SHAREREPORT = 2,
    APPROVEREPORT = 3
}
export enum DownloadTypes {
    MAIL_LINK = 1,
    INTERNAL_DOWNLOAD = 2,
}


export enum profileWorkflowActionsEnum {
    // MARK_AS_VERIFICATION_INPROGRESS = 1,
    // MARK_AS_VERIFICATION_FAILED = 2,
    // MARK_AS_VERIFICATION_COMPLETED = 3,
    // MARK_AS_READY_FOR_VERIFICATION = 4
    SUBMIT_PROFILE_TO_HR = 1,
    SUBMIT_PROFILE = 2,
    REFER_BACK = 3,
    REFER_BACK_TO_EMPLOYEE = 4,
    MARK_PROFILE_AS_IN_PROGRESS = 5,
    MARK_PROFILE_AS_CANCELLED = 6,
    MARK_PROCESS_AS_COMPLETED = 7,
    MARK_PROFILE_AS_APPROVED = 8
}

export enum checkStatusTypesEnum {
    // Check_Initiated = 1,
    // Check_In_Progress = 2,
    // Check_Refer_Back = 3,
    // Check_Refer_Back_to_Employee = 4,
    // Check_Completed = 5,
    // Check_Canceled = 6,
    // Check_Details_Updated_By_Employee = 7

    Case_Initiated = 1,
    Case_InProgress = 2,
    ROC_Check = 3,
    Followupby_Phone = 4,
    Followupby_Email = 5,
    Nottobe_Initiated = 6,
    University_Genunity_Check = 7,
    Insuff_Raised = 8,
    Insuff_Cleared = 9,
    Clarification = 10,
    Internal_Update = 11,
    Amount_Approval = 12,
    Assignto_Vendor = 13,
    Requestfor_DD = 14,
    Request_for_Site_Check = 15,
    Closed = 16,
    Closedby_Vendor = 17,
    Case_ReInitiate = 18,
    Non_Vendor = 19,
    Stop_Check = 20,
    ClarificationCleared = 21,
    Amount_Approved = 22
}

export enum checkWorkflowActionsEnum {
    Mark_Case_As_Initiated = 1,
    Mark_Case_As_InProgress = 2,
    Mark_Case_As_ROCCheck = 3,
    Mark_Case_As_Followupby_Phone = 4,
    Mark_Case_As_Followupby_Email = 5,
    Mark_Case_As_Nottobe_Initiated = 6,
    Mark_Case_As_University_Genunity_Check = 7,
    Mark_Case_As_Insuff_Raised = 8,
    Mark_Case_As_Insuff_Cleared = 9,
    Mark_Case_As_Clarification = 10,
    Mark_Case_As_Internal_Update = 11,
    Mark_Case_As_Amount_Approval = 12,
    Mark_Case_As_Assignto_Vendor = 13,
    Mark_Case_As_Requestfor_DD = 14,
    Mark_Case_As_Request_for_Site_Check = 15,
    Mark_Case_As_Closed = 16,
    Mark_Case_As_Closedby_Vendor = 17,
    Mark_As_Case_ReInitiate = 18,
    Mark_As_Non_Vendor = 19,
    Stop_Check = 20,
    Mark_Case_As_Clarification_Cleared = 10,
    Amount_Approved = 22
}


export enum HRConfirmTypesEnum {
    None = 1,
    Approvals = 2,
    Insuff = 3,
    Clarifications = 4
}

export enum HRConfirmTypesLabelEnum {
    Approvals = 'Approvals',
    Insuff = 'Insuff',
    Clarifications = 'Clarifications'
}

export enum HRConfirmReqStatusTypesEnum {
    Initiated = 1,
    ApprovedAmount = 2,
    RejectedAmount = 3,
    Closed = 4,
    ApprovedClarification = 5,
    RejectedClarification = 6,
    LinkSenttoCandidate = 7,
    SubmittedCandidate = 8,
    CommentsAdded = 9,
    ReviewandSendtoClinet = 10,
    Cancelled = 11
}