import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appReviewStatusClass]'
})
export class ReviewStatusClassDirective {

  @Input() appReviewStatusClass: string; // Input for the status

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.applyClass(this.appReviewStatusClass);
  }

  private applyClass(status: string) {
    // Remove all possible classes before adding a new one
    this.renderer.removeClass(this.el.nativeElement, 'success');
    this.renderer.removeClass(this.el.nativeElement, 'warning');
    this.renderer.removeClass(this.el.nativeElement, 'danger');

    // Apply the corresponding class based on the status
    switch (status) {
      case 'Review In Progress':
        this.renderer.addClass(this.el.nativeElement, 'badge');
        this.renderer.addClass(this.el.nativeElement, 'badge-primary');
        break;
      case 'Review Success':
        this.renderer.addClass(this.el.nativeElement, 'badge');
        this.renderer.addClass(this.el.nativeElement, 'badge-success');
        break;
      case 'Review Failed':
        this.renderer.addClass(this.el.nativeElement, 'badge');
        this.renderer.addClass(this.el.nativeElement, 'badge-danger');
        break;
      case 'Not Started':
        this.renderer.addClass(this.el.nativeElement, 'badge');
        this.renderer.addClass(this.el.nativeElement, 'badge-warning');
        break;
      default:
        break;
    }
  }
}
