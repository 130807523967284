import { Component, Input, OnInit } from '@angular/core';
import { userTypesEnum } from 'src/app/common/constants/common.constants';
import { ProfileList } from 'src/app/common/models/applicant.class';
import { AuthGuard } from 'src/app/common/services/auth.service';
import { ApplicantService } from '../../applicant/services/applicant.service';

@Component({
  selector: 'app-profile-min-info',
  templateUrl: './profile-min-info.component.html',
  styleUrls: ['./profile-min-info.component.scss']
})
export class ProfileMinInfoComponent implements OnInit {
  @Input() profileId: number;
  @Input() profileGuid: number;
  model: ProfileList = new ProfileList();
  authguard: any;
  userTypes = userTypesEnum;
  constructor(
    private _applicantService: ApplicantService,
    private _authguard: AuthGuard,
  ) {
    this.authguard = _authguard;
   }

  ngOnInit() {
    this.getProfileById();
  }

  getProfileById() {
    this._applicantService
      .getProfileById({ profileId: this.profileId, profileGuid: this.profileGuid })
      .subscribe((res: ProfileList) => {
        if (res) {
          this.model = <ProfileList>res;
        }
      });
  }
}
