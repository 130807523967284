<mat-form-field appearance="outline">
  <mat-label>{{ placeHolder }}</mat-label>
  <mat-select
    placeholder="Year"
    [(ngModel)]="year"
    name="{{ name }}{{ index }}"
    [required]="isRequired"
    (selectionChange)="onChange($event)"
  >
    <!-- <mat-option [value]="1000"> None </mat-option> -->
    <mat-option *ngFor="let item of lstYear" [value]="item">
      {{ item }}
    </mat-option>
  </mat-select>
</mat-form-field>
