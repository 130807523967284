import { CommonModule } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders, NgModule
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonMaterialModule } from "src/app/app.material.module";
import { CopyToClipboardDirective } from "src/app/directives/copy-to-clipboard.directive";
import { CustomValidatorDirective } from "src/app/directives/custom-validator.directive";
import { InputvalidatorDirective } from "src/app/directives/inputVidator.directive";
import { EqualValidator } from "src/app/directives/validate-equal";
import { CapitalSpacePipe } from "src/app/pipes/capital-space.pipe";
import { FilterList } from "src/app/pipes/filter-list";
import { FinalReportColorPipe } from "src/app/pipes/final-report-color.pipe";
import { BgColorPipe } from "src/app/pipes/get-bg-color.pipe";
import { IqFilterPipe } from "src/app/pipes/iq-filter.pipe";
import { ReplacePipe } from "src/app/pipes/replace.pipe";
import { ReviewStatusColorPipe } from "src/app/pipes/review-status-color";
import { SafeHtmlPipe } from "src/app/pipes/safe-html.pipe";
import { UserNodesPipe } from "src/app/pipes/user-nodes.pipe";
import { HideControlDirective } from "../../directives/hide-control";
import { NumberValidatorDirective } from "../../directives/number-validator.directive";
import { UppercaseDirective } from "../../directives/uppercase.directive";
import { ValidateDropDownDirective } from "../../directives/validate-drop-down.directive";
import { ProfileChecksComponent } from "../applicant/components/profile-checks/profile-checks.component";
// import { ReportEditorComponent } from "../applicant/components/report/report-editor/report-editor.component";
import { CommonDialogComponent } from "./common-dialog/common-dialog.component";
import { CountryStateCascadeComponent } from "./country-state-cascade/country-state-cascade.component";
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";
import { MailComponent } from './mail/mail.component';
import { MonthDateComponent } from "./month-date/month-date.component";
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ProfileMinInfoComponent } from './profile-min-info/profile-min-info.component';
import { UserHierarchyComponent } from './user-hierarchy/user-hierarchy.component';
import { ValidateOtpComponent } from './validate-otp/validate-otp.component';
import { YearComponent } from "./year/year.component";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { IqdateRangePickerComponent } from './iqdate-range-picker/iqdate-range-picker.component';
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { NgxEditorModule } from "ngx-editor";
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from "ng2-charts";
import { IqEditorComponent } from "./iq-editor/iq-editor.component";
import { ReviewStatusClassDirective } from "src/app/directives/review-status-class.directive";
import { MultiCheckboxSearchComponent } from "./multi-checkbox-search/multi-checkbox-search.component"

@NgModule({
  declarations: [
    CustomValidatorDirective,
    InputvalidatorDirective,
    CopyToClipboardDirective,
    EqualValidator,
    HideControlDirective,
    DeleteDialogComponent,
    CommonDialogComponent,
    ProfileChecksComponent,
    IqFilterPipe,
    ReviewStatusColorPipe,
    ReplacePipe,
    SafeHtmlPipe,
    MonthDateComponent,
    YearComponent,
    ValidateDropDownDirective,
    UppercaseDirective,
    ReviewStatusClassDirective,
    NumberValidatorDirective,
    CapitalSpacePipe,
    FinalReportColorPipe,
    UserNodesPipe,
    BgColorPipe,
    CountryStateCascadeComponent,
    FilterList,
    MailComponent,
    ValidateOtpComponent,
    PdfViewerComponent,
    UserHierarchyComponent,
    // ReportEditorComponent,
    ProfileMinInfoComponent,
    ConfirmDialogComponent,
    LineChartComponent,
    PieChartComponent,
    IqdateRangePickerComponent,
    IqEditorComponent,
    MultiCheckboxSearchComponent

    // ChecksMinInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CommonMaterialModule,
    NgxEditorModule,
    BaseChartDirective,
    NgxDaterangepickerMd.forRoot()],
  exports: [
    CustomValidatorDirective,
    InputvalidatorDirective,
    CopyToClipboardDirective,
    NumberValidatorDirective,
    UppercaseDirective,
    ReviewStatusClassDirective,
    EqualValidator,
    HideControlDirective,
    DeleteDialogComponent,
    ProfileChecksComponent,
    MonthDateComponent,
    YearComponent,
    IqFilterPipe,
    ReviewStatusColorPipe,
    ReplacePipe,
    SafeHtmlPipe,
    CapitalSpacePipe,
    FinalReportColorPipe,
    UserNodesPipe,
    BgColorPipe,
    CountryStateCascadeComponent,
    FilterList,
    MailComponent,
    ValidateOtpComponent,
    PdfViewerComponent,
    UserHierarchyComponent,
    // ReportEditorComponent,
    ProfileMinInfoComponent,
    ConfirmDialogComponent,
    LineChartComponent,
    PieChartComponent,
    IqdateRangePickerComponent,
    BaseChartDirective,
    IqEditorComponent,
    MultiCheckboxSearchComponent
    // ChecksMinInfoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideCharts(withDefaultRegisterables())
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
