<div>
  <div class="row">
    <div class="col-sm-4">
      <span class="text-bold"> Name : </span>{{ model.firstName }} {{ model.lastName }}
    </div>
    <div class="col-sm-4">
      <span class="text-bold"> Email : </span>{{ model.email }}
    </div>
    <div class="col-sm-4" *ngIf="authguard.userTypeId != userTypes.ENTERPRISE_USER">
      <span class="text-bold"> Enterprise :</span> {{ model.enterprise }}
    </div>

  </div>

  <div class="row">
    <div class="col-sm-2">
      <span class="text-bold">#Profile : </span>{{ model.profileNumber }}
    </div>
    <div class="col-sm-2">
      <span class="text-bold"> Emp Code : </span>{{ model.employeeCode }}
    </div>
    <div class="col-sm-3">
      <span class="text-bold"> Profile Status : </span>{{ model.profileStatus }}
    </div>
    <div class="col-sm-2">
      <span class="text-bold">Created On : </span>{{ model.createdDate | date: "dd-MMM-yyyy" }}
    </div>

    <div class="col-sm-2"><span *ngIf="model.finalRptColorId">
        <i class="fa fa-file final-rpt-icon {{model.finalRptColorId |
        finalreportColor}}" aria-hidden="true" title="Final Report Color"></i>
      </span>
    </div>
  </div>
</div>