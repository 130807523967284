import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';

interface Option {
  id: number;
  name: string;
}

@Component({
  selector: 'app-multi-checkbox-search',
  templateUrl: './multi-checkbox-search.component.html',
  styleUrls: ['./multi-checkbox-search.component.scss'],
})
export class MultiCheckboxSearchComponent implements OnInit {
  @ViewChild('search') searchTextBox: ElementRef;
  @ViewChild('selectList') multiSelectRef: MatSelect;
  @Input() options: Option[] = [];
  @Input() placeHolder: string;
  @Output() selectionChange = new EventEmitter<Option[]>();

  searchText: string = '';
  filteredOptions: Option[] = [];
  selectedOptions: Option[] = [];
  selectedValues: Option[] = [];

  ngOnInit() {
    this.filteredOptions = this.options;
  }

  filterOptions() {
    this.setSelectedValues();
    const filterValue = this.searchText.toLowerCase();
    this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  selectAll(event: any) {
    this.multiSelectRef.options.forEach((data: MatOption) => {
      if (data.selected && data.value.id == "0")
        data.deselect()
    });
  }

  deselectAll(event: any) {
    this.multiSelectRef.options.forEach((data: MatOption) => {
      data.deselect()
    });
  }

  onSelectAllClick(event: any) {
    this.multiSelectRef.options.forEach((data: MatOption) => {
      if (data.value.id != "0" && data.value.id != "-100") // -100 is required, otherwise it will fall in loop forever
        data.select()
    });
  }

  onSelectionChange(event: any) {
    if (event.source.selected) {
      this.selectedOptions.push(event.source.value);
    } else {
      const index = this.selectedOptions.findIndex(opt => opt.id === event.source.value.id);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      }
    }
    this.setSelectedValues()
    this.selectionChange.emit(this.selectedOptions);
  }

  openedChange(e: boolean) {
    if (e) {
      this.searchText = '';
      this.filteredOptions = this.options;
      setTimeout(() => this.searchTextBox.nativeElement.focus());
    }
  }

  clearSearch(event: Event) {
    event.stopPropagation();
    this.searchText = '';
    this.filteredOptions = this.options;
  }

  setSelectedValues() {
    if (this.selectedOptions && this.selectedOptions.length > 0) {
      this.selectedOptions.forEach(e => {
        if (!this.selectedValues.includes(e)) {
          this.selectedValues.push(e);
        }
      });
    }
  }
}
