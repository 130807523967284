import {
  Component, EventEmitter,
  Input, OnInit, Output
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { MasterModel } from "src/app/common/models/applicant.class";
import { SharedService } from "../services/shared.service";

@Component({
  selector: "app-country-state-cascade",
  templateUrl: "./country-state-cascade.component.html",
  styleUrls: ["./country-state-cascade.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CountryStateCascadeComponent implements OnInit {
  lstCountryWiseStates: MasterModel[];
  private _country: any;
  private _state: any;
  @Input() lstCountries: MasterModel[];
  @Input() lstStates: MasterModel[];
  @Input() isCountryRequired: boolean;
  @Input() isStateRequired: boolean;
  @Input() index: number;
  @Input() name: string;
  @Input() matCountryHideName: string;
  @Input() matStateHideName: string;
  @Input()
  get country(): any {
    return this._country;
  }
  set country(country: any) {
    if (!this.lstStates && country) {
      this.getStates(country);
    } else if (country) {
      this.stateFilter(country);
    }

    this._country = country;
    if (country) {
      this.countryChange.emit(this._country);
    }
  }
  @Input()
  get state(): any {
    return this._state;
  }
  set state(state: any) {
    this._state = state;
    if (this._country) {
      this.stateChange.emit(this._state);
    }
  }
  @Output()
  countryChange: EventEmitter<any> = new EventEmitter<any>(true);
  @Output()
  stateChange: EventEmitter<any> = new EventEmitter<any>(true);
  lstcountry: [];

  constructor(private _sharedService: SharedService) {}

  ngOnInit() {}
  onChange(e) {
    this.country = e.value;
    if (this.lstStates && this.lstStates.length > 0) {
      this.lstCountryWiseStates = this.lstStates.filter(
        item => item.baseId === e.value
      );
    }
  }
  getStates(country: any) {
    this._sharedService.getStates().subscribe(res => {
      this.lstStates = <MasterModel[]>res;
      this.stateFilter(country);
    });
  }
  stateFilter(country: any) {
    this.lstCountryWiseStates = this.lstStates.filter(
      item => item.baseId === country
    );
  }
  onStateChange(e) {
    this.state = e.value;
  }
}
