import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "getBgColor"
})
export class BgColorPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        switch (value) {
            case 1:
                return 'bg-green';
            case 2:
                return 'bg-orange';
            case 3:
                return 'bg-red';
            default:
                return '';
                break;
        }
    }
}
