import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { userTypesEnum } from 'src/app/common/constants/common.constants';
import { UserListResponse } from 'src/app/common/models/applicant.class';
import { AuthGuard } from 'src/app/common/services/auth.service';
import { CommonService } from 'src/app/common/services/common.service';
import { UserService } from '../../user/services/user.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-user-hierarchy',
  templateUrl: './user-hierarchy.component.html',
  styleUrls: ['./user-hierarchy.component.scss']
})
export class UserHierarchyComponent implements OnInit {
  userListResponse: Array<UserListResponse>;
  userTypes = userTypesEnum;
  authguard: any;
  constructor(
    private dialogRef: MatDialogRef<UserHierarchyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _sharedService: SharedService,
    private _userService: UserService,
    private _matDialog: MatDialog,
    private _authguard: AuthGuard,
    private _commonService: CommonService,
  ) {
    this.authguard = _authguard;
  }

  ngOnInit() {
    this.getSubOrdinates();
  }

  getSubOrdinates() {
    this._userService.getSubOrdinates({ userId: this.data.userId }).subscribe(res => {
      this.userListResponse = <UserListResponse[]>res;
    });
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick() {
    this.dialogRef.close(true);
  }
  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}

export interface DialogData {
  userId: number;
}