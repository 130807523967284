import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "finalreportColor"
})
export class FinalReportColorPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        switch (value) {
            case 1:
                return 'text-green';
            case 2:
                return 'text-orange';
            case 3:
                return 'text-red';
            default:
                return '';
                break;
        }
    }
}
