<!-- multi-checkbox-search-v2.component.html -->
<mat-form-field>
  <mat-select #selectList [(ngModel)]="selectedValues" multiple #select="ngModel" (openedChange)="openedChange($event)" [placeholder]="placeHolder">
    <mat-select-trigger>
      {{ selectedValues?.length ? selectedValues[0].name : '' }}
      <span *ngIf="selectedValues?.length > 1" class="additional-selection">
        (+{{ selectedValues.length - 1 }} {{ selectedValues.length === 2 ? 'other' : 'others' }})
      </span>
    </mat-select-trigger>
    <div class="select-container">
      <mat-optgroup>
        <mat-form-field style="width:100%;">
          <input #search autocomplete="off" placeholder="search" aria-label="search" matInput [(ngModel)]="searchText" (input)="filterOptions()">
          <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="filteredOptions?.length == 0">
        <div>No results found!</div>
      </mat-optgroup>
        <mat-option (onSelectionChange)="deselectAll($event)" [value]="{baseId: 0, id: 0, name: 'None'}">None</mat-option>
        <mat-option (onSelectionChange)="selectAll($event)" (click)="onSelectAllClick($event)" [value]="{baseId: 0, id: -100, name: 'Select All'}">Select All</mat-option>           
        <mat-option (onSelectionChange)="onSelectionChange($event)" *ngFor="let option of filteredOptions" [value]="option">
          {{ option.name }}
        </mat-option>        
    </div>
  </mat-select>
</mat-form-field>
