<div class="d-flex">
  <mat-form-field appearance="outline" id="{{ matCountryHideName }}{{ index }}">
    <mat-label>Country</mat-label>
    <mat-select placeholder="Country" [(ngModel)]="country" name="{{ name }}country{{ index }}"
      [required]="isCountryRequired" (selectionChange)="onChange($event)">
      <mat-option *ngFor="let item of lstCountries" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="outline" id="{{ matStateHideName }}{{ index }}">
    <mat-label>State</mat-label>
    <mat-select placeholder="State" [(ngModel)]="state" name="{{ name }}state{{ index }}" [required]="isStateRequired"
      (selectionChange)="onStateChange($event)">
      <mat-option *ngFor="let item of lstCountryWiseStates" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>