import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommonService } from "src/app/common/services/common.service";
import { CommonDataService } from "../../../common/services/commonData.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private _commonDataService: CommonDataService,private _commonService:CommonService) {}
  getUserList<T>(filter: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/User/GetUsersList", filter)
      .pipe(map(res => <T>res));
  }
  getSubOrdinates<T>(filter: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/User/GetSubOrdinates", filter)
       .pipe(map(res => <T>res));
  }
  getUser<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/User/GetUser?id=${id}`)
       .pipe(map(res => <T>res));
  }
  addUser<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync("api/User/AddUser", data)
       .pipe(map(res => <T>res));
  }
  updateUser<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/User/UpdateUser`, data)
       .pipe(map(res => <T>res));
  }
  getUsersByEnterpriseId<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/User/GetUsersByEnterpriseId`, data)
       .pipe(map(res => <T>res));
  }
  deleteUser<T>(id: number): Observable<T> {
    return this._commonDataService
      .getDataAsync("api/User/DeleteUser?id=" + id)
       .pipe(map(res => <T>res));
  }
  changePassword<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/User/ChangePassword`, data)
       .pipe(map(res => <T>res));
  }
  getUserProfile<T>(): Observable<T> {
    return this._commonDataService
      .getDataAsync(`api/User/GetUserProfile`)
       .pipe(map(res => <T>res));
  }
  getUsersListExport(data: any) {
    this._commonDataService
      .postDataGetBlobAsync("api/User/GetUsersListExport", data)
       .pipe(map((res) => res)).subscribe((res: any) => {
        this._commonService.downLoadExcel(res, 'User.xlsx');
      });
  }
}
