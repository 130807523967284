import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OTPRequest } from 'src/app/common/models/admin.class';

@Component({
  selector: 'app-validate-otp',
  templateUrl: './validate-otp.component.html',
  styleUrls: ['./validate-otp.component.scss']
})
export class ValidateOtpComponent implements OnInit {
  @Output()
  onChangeStatus = new EventEmitter<OTPRequest>(true);
  model: OTPRequest = new OTPRequest();
  constructor(
    private dialogRef: MatDialogRef<ValidateOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public checkLogs: OTPRequest
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.model = new OTPRequest();
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick() {
    this.onChangeStatus.emit(this.model);
  }
}
