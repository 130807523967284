export class MailQueue {
  MailStatusTypeId: number;
  MailPriorityTypeId: number;
  TotalRetry: number;
  MaxRetry: number;
  IsHtml: boolean;
  LastRetryTime: string;
  TargetTime: string;
  TargetDate: string;
  EmailBody: string;
  EmailSubject: string;
  EmailCc: string;
  EmailBcc: string;
  EmailTo: string;
  EmailFrom: string;
  MailQueueId: string;
  AddedDate: string;
  pageNo: number;
  pageSize: number;
}

export class MailQueueModel {
  mailStatusTypeId: number;
  mailPriorityTypeId: number;
  totalRetry: number;
  maxRetry: number;
  isHtml: boolean;
  lastRetryTime: string;
  targetTime: string;
  targetDate: string;
  emailBody: string;
  emailSubject: string;
  emailCc: string;
  emailBcc: string;
  emailTo: string;
  emailFrom: string;
  mailStatusTypeName: string;
  mailQueueId: string;
  addedDate: string;
  pageNo: number;
  pageSize: number;
  assignedEnterprises: Array<number>;
  selectedMailStatusTypes: Array<number>;
  mailTypeIds: Array<number>;
  fromDate: Date;
  toDate: Date;
}