<div>
  <div class="text-center">
    <strong>{{data.confirmText}} </strong>
  </div>
  <hr />
  <div class="text-center">
    <button type="button" data-dismiss="modal" title="Cancel" (click)="onNoClick()" mat-raised-button
      color="default">No</button>&nbsp;&nbsp;
    <button type="submit" data-dismiss="modal" (click)="onConfirmClick()" title="Yes" mat-raised-button
      color="primary">{{data.buttonText}}</button>
  </div>
</div>