import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-iqdate-range-picker',
  templateUrl: './iqdate-range-picker.component.html',
  styleUrls: ['./iqdate-range-picker.component.scss']
})
export class IqdateRangePickerComponent implements OnInit {
  @ViewChild('datePickerInput', { static: true }) datePickerInput: ElementRef;
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  @Output() dateRangeSelectedEvent = new EventEmitter<{ startDate: moment.Moment, endDate: moment.Moment }>();
  activeRange: string;
  selected: { startDate: moment.Moment, endDate: moment.Moment };
  constructor() { }

  ngOnInit() {
    this.selected = { startDate: moment(), endDate: moment() };
    this.setDateRange('1w');
  }

  setDateRange(range: string) {
    let startDate, endDate;
    switch (range) {
      case '1d':
        startDate = moment().subtract(1, 'days');
        endDate = moment();
        break;
      case '1w':
        startDate = moment().subtract(1, 'weeks');
        endDate = moment();
        break;
      case '1m':
        startDate = moment().subtract(1, 'months');
        endDate = moment();
        break;
      case '6m':
        startDate = moment().subtract(6, 'months');
        endDate = moment();
        break;
    }
    this.selected = { startDate, endDate };
    this.activeRange = range;
    this.emitDateRange();
  }

  dateRangeSelected(event: any) {
    if (event.startDate && event.endDate) {
      this.selected = event;
      this.activeRange = 'custom';
      this.emitDateRange();
    }
  }

  emitDateRange() {
    this.dateRangeSelectedEvent.emit(this.selected);
  }

  openDatePicker() {
    this.datePickerInput.nativeElement.click();
    setTimeout(() => {
    this.pickerDirective.open();
    });
  }
}
