import { Component, Input, OnInit, OnChanges } from "@angular/core";
// import { profileChecksTypesEnum } from "src/app/common/constants/ProfileCheckTypes.const";
import { LstChecks } from "src/app/common/models/admin.class";
import { ProfileChecksConfig } from "src/app/common/models/applicant.class";

@Component({
  selector: "app-profile-checks",
  templateUrl: "./profile-checks.component.html",
  styleUrls: ["./profile-checks.component.scss"]
})
export class ProfileChecksComponent implements OnInit,OnChanges   {
  // profileCheckTypesEnum = profileChecksTypesEnum;
  @Input() lstProfileChecksConfigs: ProfileChecksConfig[];
  lstItems: ProfileChecksConfig[];
  @Input() isDashboard: boolean;
  @Input() isEnterprise: boolean;
  constructor() {}

  ngOnInit() {
    if(this.lstProfileChecksConfigs){
      this.lstItems = this.lstProfileChecksConfigs.filter(o=>!o.isHideToClient);
    }
    
  }
  ngOnChanges() {
    if(this.lstProfileChecksConfigs){
      this.lstItems = this.lstProfileChecksConfigs.filter(o=>o.isHideToClient!=true);
    }
  }
  removeItemClick(item: any) {
    item.isDisableAddItem = false;
    if (item.items > 0) {
      item.items--;
      item.isDisableRemoveItem = false;
    } else {
      item.isDisableRemoveItem = true;
    }
  }
  addItemClick(item: any) {
    item.isDisableRemoveItem = false;
    if (item.items >= item.max) {
      item.isDisableAddItem = true;
    } else {
      item.items++;
      item.isDisableAddItem = false;
    }
  }
}
