import {
  Component, Inject, OnInit
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.scss"]
})
export class DeleteDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() { }
  ngAfterViewInit() {
    if (this.data) {
      if (this.data.buttonText == undefined || this.data.buttonText == '' || this.data.buttonText == null)
        this.data.buttonText = "Delete"
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick() {
    this.dialogRef.close(true);
  }
}
export interface DialogData {
  confirmText: string;
  buttonText: string;
}
