import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "iqFilter"
})
export class IqFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || !args) {
      return value;
    }
    return _.filter(value, args);
  }
}
