import { Component, OnInit, AfterViewChecked, Input } from '@angular/core';
import { ReportModel } from 'src/app/common/models/applicant.class';

@Component({
  selector: 'app-iq-editor',
  templateUrl: './iq-editor.component.html',
  styleUrl: './iq-editor.component.scss'
})
export class IqEditorComponent implements OnInit, AfterViewChecked {
  @Input() content: string;
  contentOld: string;
  ngOnInit() {
    // @ts-ignore
    CKEDITOR.replace("ckplot");
    // @ts-ignore
    CKEDITOR.config.allowedContent = true;
    // @ts-ignore
    CKEDITOR.config.forcePasteAsPlainText = true;
    // @ts-ignore
    CKEDITOR.config.font_names = "Arial;Times New Roman;Verdana";
    // @ts-ignore
    CKEDITOR.config.height = 550;

  }
  ngAfterViewChecked() {
    if (this.content != this.contentOld) {
      this.contentOld = this.content;
      // @ts-ignore
      CKEDITOR.instances["ckplot"].setData(this.content)
    }

  }
}
