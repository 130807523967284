import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() { }

  ngAfterContentInit() {
    if (this.data) {
      if (this.data.buttonText == undefined || this.data.buttonText == '' || this.data.buttonText == null)
        this.data.buttonText = "Delete"
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick() {
    this.dialogRef.close(true);
  }
}
export interface DialogData {
  confirmText: string;
  buttonText: string;
}
